import * as React from "react"
import logoVSV from "../../images/logo-vsv.svg";
import "./footer.scss";

const currentYear = (new Date().getFullYear());
const logoVSVStyle = {
    height: '34px',
    paddingRight: '14px',
    marginBottom: '10px'
}

const Footer = () => {
    return (
        <footer id="footer" className="footer text-center bg-primary text-white py-4">
            <div className="container">
                <p><a className="text-white" href="https://www.gfamilymgt.com" title="www.gfamilymgt.com">www.gfamilymgt.com</a><br />Copyright ©{currentYear} Global Family Management. All rights reserved.</p>
                <p>Rue François-Bonivard 4, 1201 Geneva - Switzerland<br /><small>+41(0)22 900 14 90</small></p>
            </div>
            <hr />
            <div className="container">
                <img src={logoVSV} alt="Logo VSV ASG" style={logoVSVStyle} />
                <p><small>Membres de l’Associaton Suisse des Gestionnaires de Fortune (ASG)<br />Affilié auprès de l'AOOS, Organisme de surveillance des gestionnaires de fortune et des Trustees</small></p>
            </div>
        </footer>
    );
}

export default Footer;